import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ReactFlow, MiniMap, ReactFlowProvider, useReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useNodeStore } from './store.js';
import CustomRFNode from './CustomRFNode.js';
import './GraphEditor.css';
/// https://reactflow.dev/examples/interaction/drag-and-drop DND 예제

function GraphEditorRF({ setIsEditGraph }) {
    const { nodes, setNodes, currentNodes, setCurrentNodes, appendNode, initialNodeIndex, setInitialNodeIndex, setNodePosition } = useNodeStore();
    const [currentEdges, setCurrentEdges] = useState([]);

    const { screenToFlowPosition } = useReactFlow();

    const saveRulesToServer = async () => {    
        try {
            const response = await fetch('https://api.inbodychat.kr/saverules', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nodes: nodes,
                    initialNodeIndex: parseInt(initialNodeIndex),
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to save rules');
            }
    
            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            console.error('Error saving rules to server:', error);
        }
    };

    const handleRightClick = (e) => {
        e.preventDefault();

        const canvasPosition = screenToFlowPosition({ x: e.clientX, y: e.clientY }); // 마우스 위치를 캔버스 위치로 변환
        
        const newNode = {
            top: canvasPosition.y,
            left: canvasPosition.x,
            title: ``,
            options: [{ title: "", connectedTo: null }, { title: "", connectedTo: null }]
        };

        appendNode(newNode);
    };

    useEffect(() => {
        const tempNodes = [];
        const tempEdges = [];
        nodes.forEach((node, index) => {
            tempNodes.push({
                id: `${index}`,
                type: 'customRF',
                position: { x: node.left, y: node.top },
                data: { node: node, nodeIndex: index, title: node.title, options: node.options }
            });
            node.options?.forEach((option, optionIndex) => {
                const handleId = String.fromCharCode(97 + optionIndex);
                option.connectedTo && tempEdges.push({
                    id: `e${index}-${optionIndex}-${option.connectedTo}`,
                    source: `${index}`,
                    target: `${option.connectedTo}`,
                    sourceHandle: handleId
                });
            });
        });
        setCurrentNodes(tempNodes);
        setCurrentEdges(tempEdges);
    }, [nodes]);

    const saveNodes = () => {
        const nodesString = JSON.stringify(nodes, null, 2);
        const blob = new Blob([nodesString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'QNA.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const resetFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result);
                setNodes(jsonData);
            } catch (error) {
                console.error("Invalid JSON file");
            } finally {
                resetFileInput();
            }
        };
        reader.readAsText(file);
    };

    const nodeTypes = useMemo(() => ({ customRF: CustomRFNode }), []);

    const onNodesChange = useCallback(
        (changes) => {
            if (!changes.length || !changes[0]?.position) {
                return;
            }
            
            const { dragging, position } = changes[0];
            if (dragging === false || isNaN(position.x) || isNaN(position.y)) {
                return;
            }
            
            setNodePosition(parseInt(changes[0].id), {
                top: position.y,
                left: position.x,
            });
        },
        [setNodePosition]
    );

    function MiniMapNode({ x, y }) {
        return <circle cx={x} cy={y} r="80" fill='blue'/>;
    }

    return (
        <div className="editor-wrap">
            <div className="editor-title-wrap">
                <div className="editor-title">FAQ Flow Chart</div>
                <div className="btn-wrap">
                    <label>Initial Question: </label>
                    <input className="set-init-index" value={initialNodeIndex} type='number' onChange={(e)=> setInitialNodeIndex(e.target.value)}></input>
                    <div className="import-btn">
                        <input type="file" accept=".json" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} />
                        <label htmlFor="fileInput" className="file-select-button">
                            Import
                        </label>
                    </div>
                    <div className="save-btn" onClick={() => saveRulesToServer()}>Save</div>
                    <div className="save-btn" onClick={() => saveNodes()}>Download</div>
                    <div className="close-btn" onClick={() => { setIsEditGraph(false); saveRulesToServer(); }}>Close</div>
                </div>
            </div>
            <div style={{ width: '100vw', height: '100vh' }}>
                <ReactFlow nodes={currentNodes} edges={currentEdges} onNodesChange={onNodesChange} nodeTypes={nodeTypes} onPaneContextMenu={handleRightClick}>
                    <MiniMap pannable={true} zoomable={true} nodeStrokeWidth={3} zoomStep={1} nodeComponent={MiniMapNode} />
                </ReactFlow>
            </div>
        </div>
    );
}

// Wrapping the component with ReactFlowProvider
function GraphEditorRFWithProvider(props) {
    return (
        <ReactFlowProvider>
            <GraphEditorRF {...props} />
        </ReactFlowProvider>
    );
}

export default GraphEditorRFWithProvider;